import { template as template_c20b11390f7449599c124a0fa2abf58c } from "@ember/template-compiler";
const FKText = template_c20b11390f7449599c124a0fa2abf58c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
