import { template as template_e3ce87a878534680826a399330e8d14e } from "@ember/template-compiler";
const FKLabel = template_e3ce87a878534680826a399330e8d14e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
