import { template as template_073d4b9822524d1ca4a988bdb29f53d6 } from "@ember/template-compiler";
const WelcomeHeader = template_073d4b9822524d1ca4a988bdb29f53d6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
