import { template as template_81948d77ab0c455597d8c66cc1aaf80a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_81948d77ab0c455597d8c66cc1aaf80a(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
