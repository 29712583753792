import { template as template_3c426c722b2d404c8d35d38d4450b75f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3c426c722b2d404c8d35d38d4450b75f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
